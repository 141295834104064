.home {
  .services {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(252, 251, 251, 0.25) 0.1%,
      rgba(202, 142, 99, 0.45) 20.84%,
      rgba(187, 110, 54, 0.6) 50%,
      rgba(197, 132, 84, 0.45) 80.94%,
      rgba(202, 142, 99, 0.25) 88.54%,
      #ffffff 100%
    );
    min-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -155px;

    .service-card {
      height: 400px;
      width: 375px;
      border-radius: 16px;
      box-shadow: 6px 6px 20px 0px rgba(41, 80, 115, 0.46);
      background-color: #fcfcfc;

      .service-card-content {
        direction: column;
        align-items: center;
        height: 100%;
        margin: 15% 5%;
        color: var(--Dark-Blue, #295073);
        font-style: normal;
        font-weight: 400;

        .service-card-header {
          margin-bottom: 15%;
          font-family: Josefin Sans;
          font-size: 32px;
          line-height: normal;
          letter-spacing: -1.6px;
        }

        .service-card-message {
          text-align: center;
          font-family: Josefin Sans;
          font-size: 28px;
          line-height: normal;
          letter-spacing: -1.44px;
          margin-bottom: 15%;
        }

        .service-image {
          height: 100px;
        }
      }
    }
  }
}
