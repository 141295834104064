.home {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: fit-content;
}

/* Full Size Devices */
@media (min-width: 1025px) {
  .home {
  }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 1024px) {
  .home {
  }
}

/* Phones */
@media (max-width: 600px) {
  .home {
  }
}
