@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

html,
body,
#root,
#app,
#app > div {
  margin: 0;
  height: 100%;
}
