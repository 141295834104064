.home {
  .testimonials {
    background: linear-gradient(
      180deg,
      #fcfcfc 0%,
      rgba(252, 251, 251, 0.25) 0.1%,
      rgba(202, 142, 99, 0.45) 20.84%,
      RGBA(187, 110, 54, 0.6) 50%,
      rgba(197, 132, 84, 0.45) 80.94%,
      rgba(202, 142, 99, 0.25) 88.54%,
      #fcfcfc 100%
    );
    min-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;

    .testimonials-card {
      height: 400px;
      width: 65%;
      border-radius: 16px;
      box-shadow: 6px 6px 20px 0px rgba(41, 80, 115, 0.46);
      background-color: #fcfcfc;

      .testimonials-card-content {
        align-items: center;
        height: 100%;
        justify-content: space-evenly;

        .testimonial {
          color: #1763a6;
          text-align: center;
          font-family: Josefin Sans;
          font-size: 40px;
          font-style: normal;
          line-height: normal;
          letter-spacing: -2.4px;
          padding: 50px;
        }
      }
    }
  }
}
